import { getConfigs } from '../confs/store.conf';
import { FLAGS } from '../confs/flags.conf';
import { isErrorPage } from './base.utils';
import loadScript from './load-script';
import { needsMerchantNav, loadMerchantNav } from './merchant-nav';
import { getCustomizedHostname } from './customization.utils';

/**
 * Fetches the feature flag for the new design of quote version details.
 *
 * Note: The use of fetch for the feature flag is temporary until it becomes GA on all customized hosts.
 * The fetch call is defined this way because external-nav is the first to load, and using a batch call would require altering
 * the overall project organization to consume the flags in a different order.
 *
 */

const customizedHostnames = getCustomizedHostname();
const signatureURLregexp = new RegExp(/^([0-9a-z-_])+(\.test\.devappdirect\.me$)/, 'i');
const signatureURLTestregexp = new RegExp(/^([0-9a-z-_])+(\.testcatalog\.appdirect\.com$)/, 'i');

const isAppSmartTestEnv = () => (
	(signatureURLregexp.test(window.location.hostname) ||
		signatureURLTestregexp.test(window.location.hostname) ) &&
	getConfigs()?.CHANNEL_SETTINGS?.partner === 'APPSMART'
);


if (
	customizedHostnames || isAppSmartTestEnv()
) {
	if (!isErrorPage()) {
		if (!(window.location.pathname.includes('v2/quotes/versions/'))) {
			loadScript(`${location.origin}/appsmart-nav/appsmart-nav.js`, null, true);
		} else {
			fetch(`${location.origin}/api/appconfigr/v1/evaluation/flag`, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					defaultValue: false,
					entityContext: { tenant: customizedHostnames?.tenant || 'APPSMART' },
					flagKey: FLAGS.QUOTE_VERSION_DETAILS_NEW_DESIGN_FLAG
				})
			})
			.then(response => response.json())
			.then(flag => {
				if (!(flag && flag.variant)) {
					loadScript(`${location.origin}/appsmart-nav/appsmart-nav.js`, null, true);
				}
			})
			.catch(error => {
				console.error('Error fetching flag:', error);
			});
	}}
}


export const loadExternalNav = () => {
	const configs = getConfigs();
	if (isAppSmartTestEnv() && !isErrorPage()) {
		loadScript(`${location.origin}/appsmart-nav/appsmart-nav.js`, null, true);
	}

	if (
		configs.CHANNEL_SETTINGS.partner === 'APPSMART' || 
		configs.CHANNEL_SETTINGS.partner === 'APPSMARTSANDBOX'
	) {
		return true;
	}
	if (needsMerchantNav()) {
		loadMerchantNav();
		return true;
	}
	return false;
};
