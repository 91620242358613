const customizedHostnames = [
    { url: 'appsmartsandbox.byappdirect.com', tenant: 'APPSMARTSANDBOX' },
    { url: 'testappsmart.appdirect.com', tenant: 'APPSMART' },
    { url: 'marketplace.appsmart.com', tenant: 'APPSMART' },
    { url: 'catalog.appdirect.com', tenant: 'APPSMART' },
    { url: 'catalogsandbox.byappdirect.com', tenant: 'APPSMARTSANDBOX' },
    { url: 'appsmart.cyanopica.appdirect-test.io', tenant: 'APPSMART' },
    { url: 'testcatalog.appdirect.com', tenant: 'APPSMART' },
    { url: 'des.test.devappdirect.me', tenant: 'DES' },
    { url: 'dataops.test.devappdirect.me', tenant: 'DATAOPS' }
];

export const getCustomizedHostname = () => {
    return customizedHostnames.find(host => host.url === window.location.hostname) || null;
};
